import WhatsappService from "@/services/resources/WhatsappService";
import WhatsappServiceNew from "@/services/resources/WhatsappServiceNew";

const serviceWhatsappNew = WhatsappServiceNew.build();
const serviceWhatsapp = WhatsappService.build();


const mensageService = () => {

  if(flag) {
    return serviceWhatsappNew
  }
  return serviceWhatsapp
}

let inModal = false
let flag = false

function vaidData(data, vm) {
  
  if(data.audio && !data.midias){
    vm.$bvToast.toast("Obrigatório selecionar um áudio", {
      title: "Enviar mensagem WhatsApp",
      variant: "danger",
      autoHideDelay: 5000,
      appendToast: true,
    });
    return false;
  }
  if (typeof data.message == "string" && data.message.trim() == "" && !data.audio) {
    vm.$bvToast.toast("Obrigatório preencher o campo de texto", {
      title: "Enviar mensagem WhatsApp",
      variant: "danger",
      autoHideDelay: 5000,
      appendToast: true,
    });
    return false;
  }
  return true;
}

function errorRequest(error, vm) {
  console.error("Erro ao enviar mensagem", error);
  vm.$bvToast.toast("Erro ao enviar mensagem", {
    title: "Enviar mensagem WhatsApp",
    variant: "danger",
    autoHideDelay: 5000,
    appendToast: true,
  });
  hideModal(vm)
}

function hideModal(vm) {
  if (inModal) {
    vm.$bvModal.hide(inModal);
  }
}

async function sendListLeads(data, vm) {
  for (let i = 0; i < data.leads.length; i++) {
    const element = data.leads[i];
    let send = {
      message: data.message,
      date_added: data.dataSend ?? data.data,
      media_ids: data.midias,
      id: `send/${element}`,
    };    
    let service = mensageService()
    await service
      .createId(send)
      .then(() => {
        if (i == 0) {
          vm.$bvToast.toast("Mensagem enviada com sucesso", {
            title: "Enviar mensagem WhatsApp",
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
          hideModal(vm)
        }
      })
      .catch((error) => {
        errorRequest(error, vm);
      });
  }
}

async function sendOnFilter(data, vm) {
  let toSend = {
    message: data.message,
    date_added: data.dataSend ?? data.data,
    media_ids: data.midias,
    filters: data.filters,
    leads: "todos",
  };

  await mensageService()
    .create(toSend)
    .then(() => {
      vm.$bvToast.toast("Mensagem enviada com sucesso", {
        title: "Enviar mensagem WhatsApp",
        variant: "success",
        autoHideDelay: 5000,
        appendToast: true,
      });
      hideModal(vm)
    })
    .catch((error) => {
      errorRequest(error, vm);
    });
}

export default async function onSubmit(data, vm, callback, modalName = false, pannelflag) {
  const dataProvided = vaidData(data, vm);
  inModal = modalName  
  flag = pannelflag

  if (dataProvided) {
    if (typeof data.leads == "string" && data.leads === "todos") {
        await sendOnFilter(data, vm).then(() =>{
        callback()
        hideModal(vm)
      });
    } else {
      await sendListLeads(data, vm).then(() =>{
        callback()
        hideModal(vm)
      });;
    }
  }
}
